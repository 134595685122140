<template>
  <v-dialog
    v-model="isShowing"
    max-width="600px"
    content-class="no-border-radius"
  >
    <v-card tile flat class="pt-4">
      <v-card-text>
        <v-alert tile type="warning">
          <span>You're about to be logged off</span>
        </v-alert>
        <div class="text-body-1">Do anything to keep working</div>
        <div>Time to logout: {{ seconds }} s...</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import tailorState from '@/state/tailor-state.js';

export default {
  data: () => ({
    isShowing: true,
    seconds: 15
  }),
  created () {
    const timerId = setInterval(() => {
      this.seconds -= 1
      if (!this.isAppIdle) {
        clearInterval(timerId)
      }

      if (this.seconds < 1) {
        clearInterval(timerId)
        if (this.isShowing) {
          this.isShowing = false;
          this.$router.push({ path: '/logout' });
        }
      }
    }, 1000)
  },
  mounted() {
    this.isShowing = tailorState.isLoggedIn();
  },
};
</script>
