import { Plugins } from '@capacitor/core';
import { eventBus } from '@/events/event-bus.js';

const accessDeniedCode = '4204a37da886464189e21295dc788ea9';

export default {
  denied() {
    return accessDeniedCode;
  },
  async startScan() {
    const continueAllowed = await this.checkPermission();
    if (!continueAllowed) {
      return {
        denied: true,
      };
    }

    eventBus.$emit('codeScanStarted');
    const { BarcodeScanner } = Plugins;
    try {
      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        eventBus.$emit('codeScanFinished');
        return {
          text: result.content,
          format: result.format,
        };
      }
    } catch {
      eventBus.$emit('codeScanFinished');
      return {
        error: true,
      };
    }
  },
  stopScan() {
    eventBus.$emit('codeScanFinished');
    const { BarcodeScanner } = Plugins;
    BarcodeScanner.stopScan();
  },
  async checkPermission() {
    const { BarcodeScanner } = Plugins;
    const status = await BarcodeScanner.checkPermission({ force: true });

    return status.granted;
  },
};
