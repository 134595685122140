import client from './clients/axios-client';
import { objectExtensions } from '@/extensions/object-extensions';
const resource = 'api/customers';

export default {
  searchByEmail(email) {
    return client.get(`${resource}/search/email/${email}`);
  },
  searchByPhone(phone) {
    return client.get(`${resource}/search/phone/${phone}`);
  },
  getById(id) {
    return client.get(`${resource}/${id}`);
  },
  getByEmail(email) {
    return client.get(`${resource}/email/${email}`);
  },
  getByPhone(phone) {
    return client.get(`${resource}/phone/${phone}`);
  },
  getUserId() {
    return client.get(`${resource}/id`);
  },
  getTailorRoles() {
    return client.get(`${resource}/roles`);
  },
  getCount() {
    return client.get(`${resource}/count`);
  },
  updateNotificationMethod(id, notificationMethod) {
    return client.put(
      `${resource}/notification/${id}`,
      objectExtensions.stringifyWithTrimmedValues(notificationMethod)
    );
  },
  updateEmail(id, email) {
    return client.put(
      `${resource}/${id}/email`,
      objectExtensions.stringifyWithTrimmedValues(email)
    );
  },
  updatePhoneNumber(id, phone) {
    return client.put(
      `${resource}/${id}/phone`,
      objectExtensions.stringifyWithTrimmedValues(phone)
    );
  },
  updateName(id, name) {
    return client.put(
      `${resource}/${id}/name`,
      objectExtensions.stringifyWithTrimmedValues(name)
    );
  },
  updateDetails(id, customer) {
    return client.put(
      `${resource}/${id}/details`,
      objectExtensions.stringifyWithTrimmedValues(customer)
    );
  },
  enroll(customerId) {
    return client.post(`${resource}/${customerId}/rewards/enroll`);
  },
  updateBehavior(customerId, behavior) {
    return client.post(
      `${resource}/${customerId}/behavior`,
      objectExtensions.stringifyWithTrimmedValues(behavior)
    );
  },
  getMeasurements(customerId) {
    return client.get(`${resource}/${customerId}/measurements`);
  },
  setMeasurements(customerId, measurements) {
    return client.post(
      `${resource}/${customerId}/measurements`,
      objectExtensions.stringifyWithTrimmedValues(measurements)
    );
  },
  sendApplePass(customerId) {
    return client.post(`${resource}/${customerId}/wallet`);
  },
};
