<template>
  <v-autocomplete
    v-model="customer"
    class="rounded-0"
    dense
    flat
    filled
    :background-color="backgroundColor"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :color="mainColor"
    hide-no-data
    clearable
    item-text="Description"
    :placeholder="$t('nav.searchCustomersPrompt')"
    prepend-icon="mdi-magnify"
    append-outer-icon="send"
    :maxlength="maxLength"
    return-object
    hide-details
    single-line
    :menu-props="{ closeOnContentClick: true }"
    @input="formatPhone"
    @change="itemChange"
    @keydown.enter="searchAdvanced"
    @click:append-outer="searchAdvanced"
  />
</template>

<script>
import customersRepository from '@/repositories/customers-repository';

export default {
  props: {
    backgroundColor: { default: 'rgba(0, 0, 0, 0.05)' },
    mainColor: { default: 'primary' },
  },
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    customer: null,
    search: null,
    maxLength: -1,
  }),
  computed: {
    items() {
      return this.entries.map((entry) => {
        let descr = `${this.$phone.formatPhoneWithCountryCode(entry.phone)}`;
        if (entry.email != null) {
          descr += `, ${entry.email}`;
        }
        if (entry.name != null) {
          descr += `, ${entry.name}`;
        }
        if (entry.surname != null) {
          descr += ` ${entry.surname}`;
        }
        const Description =
          descr.length > this.descriptionLimit
            ? descr.slice(0, this.descriptionLimit) + '...'
            : descr;

        return Object.assign({}, entry, { Description });
      });
    },
  },
  watch: {
    customer(cust) {
      if (cust !== undefined) {
        this.$emit('customerSet', cust);
        this.search = '';
      }
    },
    search(query) {
      this.$emit('queryChanged');

      if (query === undefined || query == null || query == '') return;

      if (this.customer == null) {
        this.formatPhone();
      }

      const searchMethod = query.startsWith('1 (')
        ? customersRepository.searchByPhone
        : customersRepository.searchByEmail;

      this.isLoading = true;
      searchMethod(query)
        .then((response) => {
          this.entries = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  methods: {
    itemChange() {
      this.$nextTick(() => {
        this.customer = null;
      });
    },
    searchAdvanced() {
      if (this.search === undefined || this.search == null || this.search == '')
        return;

      if (!this.search.startsWith('1 (')) {
        this.$emit('searchEmailAdvanced', this.search);
        this.entries = [];
      } else if (this.$phone.isValidPhoneQuery(this.search)) {
        this.$emit('searchPhoneAdvanced', this.search);
        this.entries = [];
      } else {
        this.$emit('searchAdvancedFailedPhoneFormat');
      }
    },
    formatPhone() {
      if (this.search === undefined || this.search == null) {
        return;
      }

      const phone = this.$phone.formatPhone(this.search);
      if (phone.startsWith('1 (')) this.maxLength = 16;
      else this.maxLength = -1;
      this.search = phone;
    },
  },
};
</script>

<style scoped>
/deep/.v-input__icon--prepend .v-icon {
  color: #5d4037;
}
/deep/.v-input__icon--append .v-icon {
  color: #5d4037;
}
/deep/.v-input__icon--append-outer .v-icon {
  color: #5d4037;
}
/deep/.v-input__icon--clear .v-icon {
  color: #5d4037;
}
/deep/.v-select__slot input {
  color: #5d4037 !important;
}
/deep/.v-select__slot ::placeholder {
  color: darkgrey !important;
}
/deep/.v-autocomplete__content {
  border-radius: 0px;
}
</style>
