import client from './clients/axios-client';
import { objectExtensions } from '@/extensions/object-extensions';
const resource = 'api/tailors';

export default {
  getAllFromShop() {
    return client.get(`${resource}/all`);
  },
  update(tailorId, tailor) {
    return client.put(
      `${resource}/${tailorId}`,
      objectExtensions.stringifyWithTrimmedValues(tailor)
    );
  },
  updatePassword(password) {
    return client.put(
      `${resource}/password`,
      objectExtensions.stringifyWithTrimmedValues(password)
    );
  },
  requestPasswordReset(email) {
    return client.post(
      `${resource}/password/reset`,
      objectExtensions.stringifyWithTrimmedValues(email)
    );
  },
  processPasswordReset(passwordModel) {
    return client.post(
      `${resource}/password/reset/${passwordModel.sessionId}`,
      objectExtensions.stringifyWithTrimmedValues(passwordModel)
    );
  },
  remove(tailorId) {
    return client.delete(`${resource}/${tailorId}`);
  },
  getById(id) {
    return client.get(`${resource}/${id}`);
  },
  getTailorId() {
    return client.get(`${resource}/id`);
  },
  postFeedback(feedback) {
    return client.post(
      `${resource}/feedback`,
      objectExtensions.stringifyWithTrimmedValues(feedback)
    );
  },
};
