export const phoneExtensions = {
  isValidPhoneQuery: (phoneQuery) => {
    return !!phoneQuery && phoneQuery.startsWith('1 (');
    // &&
    // /^1 (\([0-9]{3}\)-|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phoneQuery)
  },
  formatPhoneWithCountryCode: (phone) => {
    if (!phone) {
      return '';
    }

    if (phone.startsWith('+1')) {
      return phoneExtensions.formatPhone(phone.substr(2));
    } else if (phone.startsWith('1')) {
      return phoneExtensions.formatPhone(phone.substr(1));
    }
    return phoneExtensions.formatPhone(phone);
  },
  formatPhone: (phone) => {
    const value = phone;
    if (!value) return phone;

    const rawValue = value
      .replace('1 (', '')
      .replace(' ', '')
      .replace(/-/g, '')
      .replace(')', '');

    if (isNaN(Number(rawValue)) || !rawValue) {
      return rawValue;
    }
    let newValue = `1 (${rawValue.slice(0, 3)}`;
    if (rawValue.length > 3) {
      newValue += `)-${rawValue.slice(3, 6)}`;
    }
    if (rawValue.length > 6) {
      newValue += `-${rawValue.slice(6, 10)}`;
    }

    return newValue;
  },
  stripPhone: (phone) => {
    const value = phone;
    if (!value) return phone;

    const rawValue = value
      .replace(' ', '')
      .replace(/-/g, '')
      .replace('(', '')
      .replace(')', '');

    return rawValue;
  },
  isValidPhoneNumber: (phone) => {
    const stripped = phoneExtensions.stripPhone(phone);
    return stripped.length == 11;
  },
};
