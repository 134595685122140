import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pl from 'vuetify/es5/locale/pl';
import en from 'vuetify/es5/locale/en';
import localeHelper from '@/helpers/locale-helper';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, pl },
    current: localeHelper.getStartingLocale(),
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: '#5D4037',
        secondary: '#EFEBE9',
      },
    },
  },
});
