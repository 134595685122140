import tailorState from '@/state/tailor-state';
import customersRepository from '@/repositories/customers-repository';
import tailorsRepository from '@/repositories/tailors-repository';
import shopsRepository from '@/repositories/shops-repository';
import router from '@/router';
import { eventBus } from '@/events/event-bus.js';

export default {
  loadOnEnter() {
    const getTailorIdRequest = tailorsRepository.getTailorId();
    const getTailorRolesRequest = customersRepository.getTailorRoles();

    Promise.all([getTailorIdRequest, getTailorRolesRequest])
      .then(([idResponse, rolesResponse]) => {
        /* tailor roles response handler */
        const roles = rolesResponse.data;
        tailorState.roles = roles;
        eventBus.$emit('userRolesRefreshed');

        if (
          (window.location.pathname == '/price-list/editor' ||
            window.location.pathname == '/shop') &&
          !tailorState.roles.includes('Owner')
        ) {
          router.push({ path: '/unauthorized' });
        }
        if (tailorState.passwordResetNeeded) {
          router.push({ path: `/create-password` });
        }

        /* tailor id response handler */
        const userId = idResponse.data.id;
        const passwordResetNeeded = idResponse.data.passwordResetNeeded;
        tailorState.userId = userId;
        tailorState.passwordResetNeeded = passwordResetNeeded;
        localStorage.setItem('id', userId);
        eventBus.$emit('userIdRefreshed');

        tailorsRepository
          .getById(userId)
          .then((response) => {
            tailorState.tailor = {
              name: response.data.name,
              surname: response.data.surname,
              shopId: response.data.shopId,
            };

            shopsRepository
              .getShop(response.data.shopId)
              .then((response) => {
                tailorState.shop = response.data;
                eventBus.$emit('showSplash', false);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((errors) => {
        console.log(errors);
      });
  },

  loadOnLogin() {
    const getTailorIdRequest = tailorsRepository.getTailorId();
    const getTailorRolesRequest = customersRepository.getTailorRoles();

    Promise.all([getTailorIdRequest, getTailorRolesRequest])
      .then(([idResponse, rolesResponse]) => {
        /* tailor roles response handler */
        const roles = rolesResponse.data;
        tailorState.roles = roles;

        /* tailor id response handler */
        const userId = idResponse.data.id;
        const passwordResetNeeded = idResponse.data.passwordResetNeeded;
        tailorState.userId = userId;
        tailorState.passwordResetNeeded = passwordResetNeeded;
        localStorage.setItem('id', userId);

        tailorsRepository
          .getById(userId)
          .then((response) => {
            tailorState.tailor = {
              name: response.data.name,
              surname: response.data.surname,
              shopId: response.data.shopId,
            };

            shopsRepository
              .getShop(response.data.shopId)
              .then((response) => {
                tailorState.shop = response.data;

                eventBus.$emit('signIn');
                if (tailorState.passwordResetNeeded) {
                  router.push({ path: `/create-password` });
                } else {
                  router.push({ path: `/` });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((errors) => {
        console.log(errors);
      });
  },
};
