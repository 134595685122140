export const objectExtensions = {
  getWithTrimmedValues(obj) {
    return JSON.parse(
      JSON.stringify(obj).replace(/(\\)?"\s*|\s+"/g, ($0, $1) =>
        $1 ? $0 : '"'
      )
    );
  },
  stringifyWithTrimmedValues(obj) {
    return JSON.stringify(
      JSON.parse(
        JSON.stringify(obj).replace(/(\\)?"\s*|\s+"/g, ($0, $1) =>
          $1 ? $0 : '"'
        )
      )
    );
  },
};
