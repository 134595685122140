<template>
  <div v-if="hasVersion" class="version-info">
    <span
      class="text-subtitle-2 text-right font-italic primary--text font-weight-bold"
    >
      {{ version }}-beta
    </span>
  </div>
</template>

<script>
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;

export default {
  data: () => ({
    version: null
  }),
  computed: {
    hasVersion() {
      return this.version != null && this.version != '';
    },
  },
  mounted () {
    Device.getInfo().then((info) => {
      this.version = info.appVersion;
    });
  },
};
</script>
