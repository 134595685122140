export default {
  parseBoolean(string) {
    switch (string.toString().toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      default:
        return false;
    }
  },
  canParseBoolean(string) {
    const s = string.toString().toLowerCase().trim();
    return (
      s == '1' ||
      s == '0' ||
      s == 'true' ||
      s == 'false' ||
      s == 'yes' ||
      s == 'no'
    );
  },
};
