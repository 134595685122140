import axios from 'axios';
import configuration from '@/configuration/config-provider';
import router from '@/router';
import { eventBus } from '@/events/event-bus.js';
import tailorState from '@/state/tailor-state';
import recentPicks from '@/state/recent-picks';
const baseDomain = configuration.value('apiGateway');
const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function createResponseInterceptor() {
  const interceptor = instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status == 401) {
        instance.interceptors.response.eject(interceptor);

        const id = localStorage.getItem('id');
        const refreshToken = localStorage.getItem('refresh-token');
        const tokenUrl = `api/token?userId=${id}&token=${refreshToken}`;
        return instance
          .get(tokenUrl)
          .then((response) => {
            const token = response.data;
            localStorage.setItem('token', token);
            instance.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${token}`;
            error.config.headers['Authorization'] = `Bearer ${token}`;
            error.config.baseURL = undefined;

            return instance(error.config);
          })
          .catch((error) => {
            console.log(error);

            const savedUsername = localStorage.getItem('savedUsername');
            localStorage.clear();
            recentPicks.clear();
            if (savedUsername != null) {
              localStorage.setItem('savedUsername', savedUsername);
            }

            localStorage.removeItem('refresh-token');
            tailorState.userId = '';
            tailorState.roles = [];
            tailorState.shop = null;
            eventBus.$emit('signOut');
            eventBus.$emit('showSplash', false);

            router.push({
              path: '/login',
            });

            return Promise.reject(error);
          })
          .finally(createResponseInterceptor);
      } else {
        return Promise.reject(error);
      }
    }
  );
}

createResponseInterceptor();

export default instance;
