<template>
  <v-app-bar height="96px" app dense flat color="secondary">
    <v-container fluid class="pa-0 ma-0" fill-height>
      <v-row
        class="pl-4"
        style="height: 48px"
        align-content="center"
        align="center"
      >
        <v-toolbar-title>
          <router-link v-if="tailorState.isLoggedIn()" to="/">
            <v-img
              :src="require('@/assets/logo_b.png')"
              width="100"
              height="20"
              contain
            />
          </router-link>
          <router-link v-else to="/login">
            <v-img
              :src="require('@/assets/logo_b.png')"
              width="100"
              height="20"
              contain
            />
          </router-link>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="!tailorState.isLoggedIn()"
          tile
          text
          color="primary"
          to="/login"
        >
          <span>Login</span>
          <v-icon right>login</v-icon>
        </v-btn>
      </v-row>
      <v-row
        v-if="tailorState.isLoggedIn()"
        style="height: 48px"
        align-content="center"
      >
        <CustomersAutocompleteSearch
          class="mx-2 no-text-underline"
          style="width: 100%"
          @searchEmailAdvanced="onEmailSearch"
          @searchPhoneAdvanced="onPhoneSearch"
          @customerSet="onCustomerSet"
        />
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import CustomersAutocompleteSearch from '@/components/refactored/navbar/CustomersAutocompleteSearch';
import tailorState from '@/state/tailor-state';

export default {
  components: {
    CustomersAutocompleteSearch,
  },
  data: () => ({
    tailorState: tailorState,
  }),
  methods: {
    logout() {
      this.$router.push({ path: '/logout' });
    },
    login() {
      this.$router.push({ path: '/login' }).catch((error) => {
        // Ignore error regarding navigating to the page they are already on.
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes(
            'Avoided redundant navigation to current location'
          )
        ) {
          // Print any other errors to the console
          console.error(error);
        }
      });
    },
    onEmailSearch(query) {
      this.$router.push({
        path: '/customers/search',
        query: { searchQuery: query },
      });
    },
    onPhoneSearch(query) {
      this.$router.push({
        path: '/customers/search',
        query: { searchQuery: query },
      });
    },
    onCustomerSet(customer) {
      if (customer == null) {
        return;
      }

      this.$router.push({
        path: `/customers/details`,
        query: { id: customer.id },
      });
    },
  },
};
</script>

<style>
.no-border-radius {
  border-radius: 0px;
}
</style>

<style scoped>
.no-opacity:focus::before {
  opacity: 0 !important;
}

/deep/ .v-toolbar__content {
  border-width: 0 0 1px 0 !important;
  border-style: solid !important;
  border-color: #5d4037 !important;
}

/deep/.no-text-underline.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-style: none;
}

/deep/.no-text-underline.v-text-field
  > .v-input__control
  > .v-input__slot:after {
  border-style: none;
}

/deep/.no-text-underline.v-select > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
