<template>
  <div class="overlay-fill">
    <!-- if above does not work, add style to div => style="background: transparent" -->
    <div v-show="showBarcodeScanOverlay" class="overlay-content">
      <BarcodeScanOverlay />
    </div>
    <v-app v-show="!showBarcodeScanOverlay">
      <v-main class="secondary" style="padding: 100px 56px 0px 0px">
        <template v-if="!showSplash">
          <idle-modal v-if="isAppIdle" />
          <navbar v-if="showNavbar" />
          <div style="position: absolute; top: 0; right: 0">
            <v-alert
              v-for="notification in notifications"
              :key="notification.id"
              width="200px"
              style="z-index: 999999"
              :type="notification.type"
              >{{ notification.message }}</v-alert
            >
          </div>
          <router-view />

          <v-navigation-drawer
            v-if="tailorState.isLoggedIn()"
            v-model="smallMenu"
            v-click-outside="onClickOutside"
            class="hidden-md-and-up"
            :app="true"
            :temporary="!smallMenu"
            right
            permanent
            stateless
            :mini-variant="smallMenu"
            :disable-route-watcher="true"
            style="background: #efebe9"
          >
            <v-list class="secondary">
              <v-list-item v-if="smallMenu" @click="smallMenu = false">
                <v-list-item-action>
                  <v-icon v-if="smallMenu">menu</v-icon>
                </v-list-item-action>
                <v-list-item-content> </v-list-item-content>
              </v-list-item>
              <div v-for="item in menuItems" :key="item.nameKey">
                <v-list-item
                  v-if="item.subMenus.length === 0 && item.show"
                  router
                  exact
                  :to="item.route"
                  @click="smallMenu = true"
                >
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t(item.nameKey)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group v-else-if="item.show" no-action :value="true">
                  <template v-slot:activator>
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t(item.nameKey)
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="subItem in item.subMenus"
                    :key="subItem.nameKey"
                    router
                    exact
                    :to="subItem.route"
                    @click="smallMenu = true"
                  >
                    <v-list-item-title>{{
                      $t(subItem.nameKey)
                    }}</v-list-item-title>
                    <v-list-item-action>
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-group>
              </div>
            </v-list>
          </v-navigation-drawer>
        </template>
        <template v-if="showSplash">
          <loading-splash />
          <version-info />
        </template>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Navbar from '@/components/refactored/navbar/Navbar';
import BarcodeScanOverlay from '@/components/BarcodeScanOverlay';
import IdleModal from '@/components/refactored/common/IdleModal';
import VersionInfo from '@/components/refactored/common/VersionInfo';
import { eventBus } from '@/events/event-bus.js';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import LoadingSplash from './components/refactored/common/LoadingSplash';
import tailorState from '@/state/tailor-state';
const { StatusBar } = Plugins;

export default {
  components: {
    Navbar,
    BarcodeScanOverlay,
    IdleModal,
    VersionInfo,
    LoadingSplash,
  },
  data: () => ({
    tailorState: tailorState,
    showSplash: true,
    showNavbar: true,
    showBarcodeScanOverlay: false,
    version: null,
    showNavbarExclusions: ['/reset'],
    smallMenu: false,
    mini: true,
    smallMenus: [
      {
        icon: 'dashboard',
        nameKey: 'nav.dashboard',
        route: '/dashboard',
        show: true,
        subMenus: [],
      },
      {
        icon: 'people',
        nameKey: 'nav.customers',
        route: '/customers/search',
        show: true,
        subMenus: [],
      },
      {
        icon: 'search',
        nameKey: 'nav.ordersSub.search',
        route: '/orders/search',
        show: true,
        subMenus: [],
      },
      {
        icon: 'assessment',
        nameKey: 'nav.reports',
        route: '/reports',
        show: true,
        subMenus: [],
      },
      {
        icon: 'receipt_long',
        nameKey: 'nav.priceList',
        route: '/price-list',
        show: true,
        subMenus: [],
      },
    ],
    alert: false,
    alertMessage: '',
    notifications: [],
  }),
  computed: {
    menuItems() {
      return this.smallMenu ? this.smallMenus : this.menus;
    },
    menus() {
      return [
        {
          icon: 'dashboard',
          nameKey: 'nav.dashboard',
          route: '/dashboard',
          show: true,
          subMenus: [],
        },
        {
          icon: 'people',
          nameKey: 'nav.customers',
          route: '/customers/search',
          show: true,
          subMenus: [],
        },
        {
          icon: 'event',
          nameKey: 'nav.bookings',
          route: '/bookings',
          show: false,
          subMenus: [],
        },
        {
          icon: 'content_paste',
          nameKey: 'nav.orders',
          route: '',
          show: true,
          subMenus: [
            {
              icon: 'search',
              nameKey: 'nav.ordersSub.search',
              route: '/orders/search',
            },
            {
              icon: 'forward',
              nameKey: 'nav.ordersSub.resume',
              route: '/orders/resume',
            },
            {
              icon: 'pending_actions',
              nameKey: 'nav.ordersSub.pending',
              route: '/orders/pending',
            },
          ],
        },
        {
          icon: 'assessment',
          nameKey: 'nav.reports',
          route: '/reports',
          show: true,
          subMenus: [],
        },
        {
          icon: 'receipt_long',
          nameKey: 'nav.priceList',
          route: '/price-list',
          show: true,
          subMenus: [],
        },
        {
          icon: 'local_offer',
          nameKey: 'nav.priceListEditor',
          route: '/price-list/editor',
          show: tailorState.isOwner(),
          subMenus: [],
        },
        {
          icon: 'settings',
          nameKey: 'nav.shopSettings',
          route: '/shop',
          show: true,
          subMenus: [],
        },
        {
          icon: 'logout',
          nameKey: 'Logout',
          route: '/logout',
          show: true,
          subMenus: [],
        },
      ];
    },
  },
  mounted() {
    StatusBar.setStyle({
      style: StatusBarStyle.Dark,
    }).catch(() => {
      // supress error about no web implementation
    });

    eventBus.$on('showSplash', (show) => {
      this.showSplash = show;
    });

    eventBus.$on('error', (message) => {
      this.displayAlert(message, 'error');
    });

    eventBus.$on('success', (message) => {
      this.displayAlert(message, 'success');
    });

    eventBus.$on('warning', (message) => {
      this.displayAlert(message, 'warning');
    });

    eventBus.$on('showNavbar', (show) => {
      if (!show || !this.showNavbarExclusions.includes(this.$route.path)) {
        this.showNavbar = show;
      }
    });

    eventBus.$on('codeScanStarted', () => {
      document.querySelector('body').style.background = 'transparent';
      document.querySelector('html').style.background = 'transparent';
      document.querySelector('body').style.height = '100%';
      document.querySelector('html').style.height = '100%';
      this.showBarcodeScanOverlay = true;
    });

    eventBus.$on('codeScanFinished', () => {
      this.showBarcodeScanOverlay = false;
      document
        .querySelector('body')
        .setAttribute('style', 'overflow-x: hidden;');
      document
        .querySelector('html')
        .setAttribute('style', 'overflow-x: hidden;');
    });
  },
  methods: {
    logout() {
      this.$router.push({ path: '/logout' });
    },
    onClickOutside() {
      if (!this.smallMenu) this.smallMenu = true;
    },
    displayAlert(message, type) {
      let notification = {
        message,
        type,
      };
      this.notifications.push(notification);
      setTimeout(() => {
        let i = this.notifications.indexOf(notification);
        this.notifications.splice(i, 1);
      }, 3000);
    },
  },
};
</script>

<style scoped>
.version-info {
  position: absolute;
  bottom: 2%;
  right: 2%;
}
</style>

<style>
html,
body {
  overflow-x: hidden;
  overflow-y: auto !important;
}
.overlay-fill {
  background: transparent;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.overlay-content {
  flex: 1;
}

input {
  color: red;
  font-size: 16px;
}
</style>
