import i18n from '@/i18n';

export const colorExtensions = {
  getSwatches: function () {
    return [
      ['#ffffff', '#fffdd1', '#d2b48c', '#fb9101', '#ffff00'],
      ['#02075d', '#0066cc', '#93ff9f', '#0b6623', '#808000'],
      ['#8b4513', '#6f0006', '#c00000', '#f8abba', '#ff00ff'],
      ['#000000', '#333333', '#808080', '#d3d3d3', '#000001'],
    ];
  },
  stringToHex: function (colorText) {
    switch (colorText.toLowerCase()) {
      case i18n.tc('colors.white').toLowerCase():
        return '#ffffff';
      case i18n.tc('colors.ivory').toLowerCase():
        return '#fffdd1';
      case i18n.tc('colors.black').toLowerCase():
        return '#000000';
      case i18n.tc('colors.lightGray').toLowerCase():
        return '#d3d3d3';
      case i18n.tc('colors.gray').toLowerCase():
        return '#808080';
      case i18n.tc('colors.charcoal').toLowerCase():
        return '#333333';
      case i18n.tc('colors.navy').toLowerCase():
        return '#02075d';
      case i18n.tc('colors.blue').toLowerCase():
        return '#0066cc';
      case i18n.tc('colors.green').toLowerCase():
        return '#0b6623';
      case i18n.tc('colors.olive').toLowerCase():
        return '#808000';
      case i18n.tc('colors.yellow').toLowerCase():
        return '#ffff00';
      case i18n.tc('colors.orange').toLowerCase():
        return '#fb9101';
      case i18n.tc('colors.red').toLowerCase():
        return '#c00000';
      case i18n.tc('colors.pink').toLowerCase():
        return '#f8abba';
      case i18n.tc('colors.mint').toLowerCase():
        return '#93ff9f';
      case i18n.tc('colors.burgundy').toLowerCase():
        return '#6f0006';
      case i18n.tc('colors.tan').toLowerCase():
        return '#d2b48c';
      case i18n.tc('colors.fuchsia').toLowerCase():
        return '#ff00ff';
      case i18n.tc('colors.multicolor').toLowerCase():
        return '#000001';
      case i18n.tc('colors.brown').toLowerCase():
        return '#8b4513';
    }
  },
  hexToString: function (hex) {
    if (hex.length > 7) {
      hex = hex.substr(0, 7);
    }

    switch (hex.toLowerCase()) {
      case '#ffffff':
        return i18n.tc('colors.white');
      case '#fffdd1':
        return i18n.tc('colors.ivory');
      case '#000000':
        return i18n.tc('colors.black');
      case '#d3d3d3':
        return i18n.tc('colors.lightGray');
      case '#808080':
        return i18n.tc('colors.gray');
      case '#333333':
        return i18n.tc('colors.charcoal');
      case '#02075d':
        return i18n.tc('colors.navy');
      case '#0066cc':
        return i18n.tc('colors.blue');
      case '#0b6623':
        return i18n.tc('colors.green');
      case '#808000':
        return i18n.tc('colors.olive');
      case '#ffff00':
        return i18n.tc('colors.yellow');
      case '#fb9101':
        return i18n.tc('colors.orange');
      case '#c00000':
        return i18n.tc('colors.red');
      case '#f8abba':
        return i18n.tc('colors.pink');
      case '#93ff9f':
        return i18n.tc('colors.mint');
      case '#6f0006':
        return i18n.tc('colors.burgundy');
      case '#d2b48c':
        return i18n.tc('colors.tan');
      case '#ff00ff':
        return i18n.tc('colors.fuchsia');
      case '#000001':
        return i18n.tc('colors.multicolor');
      case '#8b4513':
        return i18n.tc('colors.brown');
    }
  },
  getRandomColor(seed) {
    if (!seed) {
      seed = Math.random().toString(36).substring(7);
    }

    var hash = 0;
    for (let i = 0; i < seed.length; i++) {
      hash = seed.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  },
  getTextColorClassForBackgroundColor(backgroundColorHex) {
    backgroundColorHex = backgroundColorHex.replace('#', '');

    var r = parseInt(backgroundColorHex.substr(0, 2), 16);
    var g = parseInt(backgroundColorHex.substr(2, 2), 16);
    var b = parseInt(backgroundColorHex.substr(4, 2), 16);

    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? 'black--text' : 'white--text';
  },
};
