<template>
  <div class="barcode-scan-overlay">
    <div class="barcode-scanner--area--container">
      <div class="relative">
        <p>Aim your camera at a barcode</p>
      </div>
      <div class="square surround-cover">
        <div class="barcode-scanner--area--outer surround-cover">
          <div class="barcode-scanner--area--inner">
            <div class="barcode-scanner-line-wrapper">
              <div class="barcode-scanner-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="close-button">
      <v-btn fab color="#5D4037" dark @click="stopScan">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import scanHelper from '@/helpers/scan-helper';

export default {
  deactivated() {
    this.stopScan();
  },
  beforeDestroy() {
    this.stopScan();
  },
  methods: {
    stopScan() {
      scanHelper.stopScan();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
p {
  color: #fff;
  font-family: sans-serif;
  text-align: center;
  font-weight: 600;
}
html,
body,
.barcode-scan-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}
.barcode-scan-overlay {
  display: flex;
}
.relative {
  position: relative;
  z-index: 1;
}
.square {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}
.square:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.square > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.surround-cover {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}
.barcode-scanner--area--container {
  width: 80%;
  max-width: min(500px, 80vh);
  margin: auto;
}
.barcode-scanner--area--outer {
  display: flex;
  border-radius: 1em;
}
.barcode-scanner--area--inner {
  width: 100%;
  margin: 1rem;
  border: 2px solid #fff;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.5),
    inset 0px 0px 2px 1px rgb(0 0 0 / 0.5);
  border-radius: 1rem;
}
.barcode-scanner-line-wrapper {
  margin-top: 5%;
  margin-left: 2.5%;
  height: 100%;
  width: 95%;
  animation: scannerline 3s infinite;
  display: inline-block;
}
.barcode-scanner-line {
  background-color: tomato;
  height: 2px;
  z-index: 2;
  box-shadow: 0 0 4px red;
  animation: beam 0.01s infinite;
}
.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
}

@keyframes scannerline {
  50% {
    transform: translateY(90%);
  }
}
@keyframes beam {
  50% {
    opacity: 0;
  }
}
</style>
