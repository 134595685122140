import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import axiosClient from '@/repositories/clients/axios-client';
import entryDataLoader from '@/helpers/entry-data-loader';
import { dateExtensions } from '@/extensions/date-extensions';
import { colorExtensions } from '@/extensions/color-extensions';
import { phoneExtensions } from '@/extensions/phone-extensions';
import { customerExtensions } from '@/extensions/customer-extensions';
import { objectExtensions } from '@/extensions/object-extensions';
import { eventBus } from '@/events/event-bus.js';
import vueHeadful from 'vue-headful';
import IdleVue from 'idle-vue';
import store from './state/vuex-store';
import VueCookies from 'vue-cookies';
import i18n from './i18n';
import VueMask from 'v-mask';
import './styles.css';

Vue.config.productionTip = false;
Vue.prototype.$dateExtensions = dateExtensions;
Vue.prototype.$colorEx = colorExtensions;
Vue.prototype.$objectExtensions = objectExtensions;
Vue.prototype.$phone = phoneExtensions;
Vue.prototype.$custEx = customerExtensions;

Vue.prototype.$showError = function (message) {
  eventBus.$emit('error', message);
};

Vue.prototype.$showSuccess = function (message) {
  eventBus.$emit('success', message);
};

Vue.prototype.$showWarning = function (message) {
  eventBus.$emit('warning', message);
};

Date.prototype.toLocaleDateString = function () {
  return `${this.getMonth() + 1}/${this.getDate()}/${this.getFullYear()}`;
};
Date.prototype.addDays = function (days) {
  var ms = new Date().getTime() + 86400000 * days;
  return new Date(ms);
};
Vue.filter('toLocalDate', function (date) {
  const language = getLanguage();
  return new Date(date + 'Z').toLocaleDateString(language);
});
Vue.filter('toShortUsDateFromUtc', function (date) {
  if (!date) {
    return null;
  }
  if (date.length > 10) {
    date = date.substr(0, 10);
  }

  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
});
Vue.filter('toLocalShortDate', function (date) {
  const language = getLanguage();
  const options = { day: 'numeric', month: 'short' };
  return new Date(date).toLocaleDateString(language, options);
});
Vue.filter('toLocalTime', function (date) {
  const language = getLanguage();
  return new Date(date).toLocaleTimeString(language);
});

function getLanguage() {
  if (window.navigator.languages) {
    return window.navigator.languages[0];
  } else {
    return window.navigator.userLanguage || window.navigator.language;
  }
}

Vue.filter('toLocalDateTime', function (date) {
  const language = getLanguage();
  const d = new Date(date + 'Z');
  return `${d.toLocaleDateString(language)} ${d.toLocaleTimeString(language)}`;
});

Vue.filter('toOrderStatus', function (status) {
  switch (status) {
    case 1:
      return i18n.tc('orders.statuses.resume');
    case 2:
      return i18n.tc('orders.statuses.checkIn');
    case 3:
      return i18n.tc('orders.statuses.pending');
    case 4:
      return i18n.tc('orders.statuses.checkOut');
    case 5:
      return i18n.tc('orders.statuses.complete');
    case 6:
      return i18n.tc('orders.statuses.moved');
    case 7:
      return i18n.tc('orders.statuses.realt');
  }
  return 'Unknown';
});

Vue.filter('formatTime', function (timeInMinutes) {
  const hours = Math.floor(timeInMinutes / 60);
  let result = '';
  if (hours >= 1) {
    result += `${hours}h `;
  }
  result += `${timeInMinutes % 60}min`;
  return result;
});

Vue.filter('formatTimeReport', function (timeInMinutes) {
  return (timeInMinutes / 60).toFixed(2);
});

Vue.filter('formatPhone', function (phone) {
  return phoneExtensions.formatPhone(phone);
});

Vue.filter('toDayOfWeek', function (dayNumber) {
  switch (dayNumber) {
    case 0:
      return i18n.tc('dayOfWeek.sunday');
    case 1:
      return i18n.tc('dayOfWeek.monday');
    case 2:
      return i18n.tc('dayOfWeek.tuesday');
    case 3:
      return i18n.tc('dayOfWeek.wednesday');
    case 4:
      return i18n.tc('dayOfWeek.thursday');
    case 5:
      return i18n.tc('dayOfWeek.friday');
    case 6:
      return i18n.tc('dayOfWeek.saturday');
  }
});

Vue.filter('toDayOfWeekShort', function (dayNumber) {
  switch (dayNumber) {
    case 0:
      return i18n.tc('dayOfWeek.shorts.sunday');
    case 1:
      return i18n.tc('dayOfWeek.shorts.monday');
    case 2:
      return i18n.tc('dayOfWeek.shorts.tuesday');
    case 3:
      return i18n.tc('dayOfWeek.shorts.wednesday');
    case 4:
      return i18n.tc('dayOfWeek.shorts.thursday');
    case 5:
      return i18n.tc('dayOfWeek.shorts.friday');
    case 6:
      return i18n.tc('dayOfWeek.shorts.saturday');
  }
});

Vue.filter('toPayment', function (payment) {
  if (payment == 0) {
    return i18n.tc('orders.payment.none');
  } else if (payment == 1) {
    return i18n.tc('orders.payment.cash');
  } else if (payment == 2) {
    return i18n.tc('orders.payment.check');
  } else if (payment == 3) {
    return i18n.tc('orders.payment.card');
  } else if (payment == 4) {
    return i18n.tc('orders.payment.other');
  }
});

Vue.filter('toErrorMessage', function (errorCode) {
  switch (errorCode) {
    case 'DUP_MAIL':
      return i18n.tc('tailors.errors.dupEmail');
    case 'DUP_PHONE':
      return i18n.tc('tailors.errors.dupPhone');
    case 'OWNER_DEL':
      return i18n.tc('tailors.errors.ownerDeletion');
    case 'INVALID_REFERRER_CD':
      return i18n.tc('tailors.errors.invalidReferrer');
    case 'DuplicateUserName':
      return i18n.tc('tailors.errors.dupUsername');
    case 'PasswordTooShort':
      return i18n.tc('tailors.errors.short');
    case 'PasswordRequiresNonAlphanumeric':
      return i18n.tc('tailors.errors.alphanumeric');
    case 'PasswordRequiresDigit':
      return i18n.tc('tailors.errors.digit');
    case 'PasswordRequiresUpper':
      return i18n.tc('tailors.errors.upper');
    case 'PasswordRequiresLower':
      return i18n.tc('tailors.errors.lower');
    case 'EMPTY_VALUE':
      return i18n.tc('tailors.errors.emptyValue');
  }
  return i18n.tc('tailors.errors.unknown');
});

Vue.component('vue-headful', vueHeadful);

Vue.use(IdleVue, {
  eventEmitter: eventBus,
  store,
  idleTime: 3 * 60 * 1000,
  startAtIdle: false,
});
Vue.use(VueCookies);
Vue.use(VueMask);

window.onload = function () {
  if (
    window.location.pathname != '/login' &&
    window.location.pathname != '/forgot'
  ) {
    axiosClient.defaults.headers.common.Authorization =
      'bearer ' + localStorage.getItem('token');
    const offset = new Date().getTimezoneOffset();
    axiosClient.defaults.headers.common['X-Timezone-Offset'] = -offset;

    entryDataLoader.loadOnEnter();
  }

  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');

  if (
    window.location.pathname == '/login' ||
    window.location.pathname == '/forgot'
  ) {
    eventBus.$emit('showSplash', false);
  }
};
