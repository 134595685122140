const instance = {
  customersThreshold: 5,
  ordersThreshold: 5,
  customers: [],
  orders: [],
  queueCustomer(customer) {
    if (!this.customers.some((c) => c.id == customer.id)) {
      this.customers.push(customer);
    }

    if (this.customers.length > this.customersThreshold) {
      this.customers.shift();
    }
  },
  queueOrder(order) {
    if (!this.orders.some((o) => o.id == order.id)) {
      this.orders.push(order);
    }

    if (this.orders.length > this.ordersThreshold) {
      this.orders.shift();
    }
  },
  refreshCustomerData(id, phone, email) {
    const cust = this.customers.find((c) => c.id == id);
    if (cust == null) {
      return;
    }

    cust.phone = phone;
    cust.email = email;
  },
  clear() {
    this.orders = [];
    this.customers = [];
  },
};

export default instance;
