import client from './clients/axios-client';
import { objectExtensions } from '@/extensions/object-extensions';
const resource = 'api/shops';

export default {
  getTypes() {
    return client.get(`${resource}/garments/types`);
  },
  getCategories(typeName) {
    return client.get(
      `${resource}/garmentcategories/${encodeURIComponent(
        encodeURIComponent(typeName)
      )}`
    );
  },
  getServicesByTypeAndCategory(typeName, categoryName) {
    return client.get(
      `${resource}/garments/services/${encodeURIComponent(
        encodeURIComponent(typeName)
      )}/${encodeURIComponent(encodeURIComponent(categoryName))}`
    );
  },
  getShop(shopId) {
    return client.get(`${resource}/${shopId}`);
  },
  getShopSettings(shopId) {
    return client.get(`${resource}/${shopId}/configuration`);
  },
  updateSetting(setting) {
    return client.post(
      `${resource}/configuration`,
      objectExtensions.stringifyWithTrimmedValues(setting)
    );
  },
  updateShop(shopId, shop) {
    return client.put(
      `${resource}/${shopId}`,
      objectExtensions.stringifyWithTrimmedValues(shop)
    );
  },
  getTailor(tailorId) {
    return client.get(`${resource}/tailors/${tailorId}`);
  },

  setTailorPercentage(tailorId, percentage) {
    return client.post(
      `${resource}/tailors/${tailorId}/percentage`,
      objectExtensions.stringifyWithTrimmedValues(percentage)
    );
  },
};
