const instance = {
  passwordResetNeeded: false,
  userId: '',
  roles: [],
  shop: null,
  tailor: null,
  isLoggedIn() {
    return this.userId != '';
  },
  isOwner() {
    return this.roles.includes('Owner');
  },
  latestOrders: [],
  storeOrder(order) {
    const existingOrder = this.latestOrders.find((x) => x.id === order.id);

    var index = this.latestOrders.indexOf(existingOrder);

    if (index > -1) {
      this.latestOrders.splice(index, 1);
      this.latestOrders.unshift(order);
      return;
    }

    if (this.latestOrders.length == 3) {
      this.latestOrders.pop();
    }
    this.latestOrders.unshift(order);
  },
};

export default instance;
