<template>
  <v-app-bar height="96" app flat tile color="secondary" dark>
    <v-container fluid class="ma-0 pa-0" fill-height>
      <v-row class="mx-2 pa-0 pb-1" align-content="center" align="center">
        <router-link v-if="tailorState.isLoggedIn()" to="/">
          <v-img
            class="shrink mr-12"
            contain
            :src="require('@/assets/logo_b.png')"
            height="32"
            width="120"
            position="left"
            transition="scale-transition"
          />
        </router-link>
        <router-link v-else to="/login">
          <v-img
            class="shrink mr-12"
            contain
            :src="require('@/assets/logo_b.png')"
            height="32"
            width="120"
            position="left"
            transition="scale-transition"
          />
        </router-link>
        <customers-autocomplete-search
          v-if="tailorState.isLoggedIn()"
          class="mx-2 no-text-underline"
          @searchEmailAdvanced="onEmailSearch"
          @searchPhoneAdvanced="onPhoneSearch"
          @customerSet="onCustomerSet"
        />
        <v-spacer />
        <v-btn
          v-if="tailorState.isLoggedIn()"
          tile
          text
          color="primary"
          to="/logout"
        >
          <span>Sign out</span>
          <v-icon right>logout</v-icon>
        </v-btn>
        <v-btn v-else tile text color="primary" to="/login">
          <span>Login</span>
          <v-icon right>login</v-icon>
        </v-btn>
      </v-row>
      <v-row
        v-if="tailorState.isLoggedIn()"
        class="mx-2 pa-0"
        align-content="center"
        align="center"
      >
        <template v-for="item in menus.filter((item) => item.show)">
          <v-btn
            v-if="item.subMenus.length == 0"
            :key="item.nameKey + 'long'"
            color="primary"
            class="mr-1 hidden-md-only"
            text
            tile
            small
            router
            exact
            :to="item.route"
          >
            <v-icon left>{{ item.icon }}</v-icon>
            <span>{{ $t(item.nameKey) }}</span>
          </v-btn>
          <v-btn
            v-if="item.subMenus.length == 0"
            :key="item.nameKey + 'short'"
            color="primary"
            class="mr-1 hidden-lg-and-up"
            text
            tile
            small
            router
            exact
            :to="item.route"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
          <v-menu v-else :key="item.nameKey" offset-y rounded="0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="mr-1 hidden-md-only"
                text
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>{{ item.icon }}</v-icon>
                <span>{{ $t(item.nameKey) }}</span>
                <v-icon right>expand_more</v-icon>
              </v-btn>
              <v-btn
                tile
                class="mr-1 hidden-lg-and-up"
                text
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ item.icon }}</v-icon>
                <v-icon right>expand_more</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="subItem in item.subMenus"
                  :key="subItem.nameKey"
                  dense
                  router
                  exact
                  :to="subItem.route"
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{
                    $t(subItem.nameKey)
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import CustomersAutocompleteSearch from '@/components/refactored/navbar/CustomersAutocompleteSearch';
import tailorState from '@/state/tailor-state';

export default {
  components: {
    CustomersAutocompleteSearch,
  },
  props: ['menus'],
  data: () => ({
    tailorState: tailorState,
  }),
  methods: {
    onEmailSearch(query) {
      this.$router.push({
        path: '/customers/search',
        query: { searchQuery: query },
      });
    },
    onPhoneSearch(query) {
      this.$router.push({
        path: '/customers/search',
        query: { searchQuery: query },
      });
    },
    onCustomerSet(customer) {
      if (customer == null) {
        return;
      }

      this.$router.push({
        path: `/customers/details`,
        query: { id: customer.id },
      });
    },
  },
};
</script>

<style scoped>
/deep/ .v-toolbar__content {
  border-width: 0 0 1px 0 !important;
  border-style: solid !important;
  border-color: #5d4037 !important;
}
/deep/.no-text-underline.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-style: none;
}
/deep/.no-text-underline.v-text-field
  > .v-input__control
  > .v-input__slot:after {
  border-style: none;
}
/deep/.no-text-underline.v-select > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
