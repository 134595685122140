<template>
  <div class="navbar">
    <NavbarMobile :menus="menus" class="hidden-md-and-up" />
    <NavbarDesktop :menus="menus" class="hidden-sm-and-down" />
  </div>
</template>

<script>
import NavbarMobile from '@/components/refactored/navbar/NavbarMobile';
import NavbarDesktop from '@/components/refactored/navbar/NavbarDesktop';
import shopsRepository from '@/repositories/shops-repository';
import tailorState from '@/state/tailor-state.js';
import { eventBus } from '@/events/event-bus.js';
import parser from '@/helpers/parse-helper';

export default {
  components: {
    NavbarMobile,
    NavbarDesktop,
  },
  data: () => ({
    isOwner: false,
    menus: [
      {
        icon: 'dashboard',
        nameKey: 'nav.dashboard',
        route: '/dashboard',
        show: true,
        subMenus: [],
      },
      {
        icon: 'people',
        nameKey: 'nav.customers',
        route: '/customers/search',
        show: true,
        subMenus: [],
      },
      {
        icon: 'event',
        nameKey: 'nav.bookings',
        route: '/bookings',
        show: false,
        subMenus: [],
      },
      {
        icon: 'content_paste',
        nameKey: 'nav.orders',
        route: '',
        show: true,
        subMenus: [
          {
            icon: 'search',
            nameKey: 'nav.ordersSub.search',
            route: '/orders/search',
          },
          {
            icon: 'forward',
            nameKey: 'nav.ordersSub.resume',
            route: '/orders/resume',
          },
          {
            icon: 'pending_actions',
            nameKey: 'nav.ordersSub.pending',
            route: '/orders/pending',
          },
        ],
      },
      {
        icon: 'assessment',
        nameKey: 'nav.reports',
        route: '/reports',
        show: true,
        subMenus: [],
      },
      {
        icon: 'receipt_long',
        nameKey: 'nav.priceList',
        route: '/price-list',
        show: true,
        subMenus: [],
      },
      {
        icon: 'local_offer',
        nameKey: 'nav.priceListEditor',
        route: '/price-list/editor',
        show: false,
        subMenus: [],
      },
      {
        icon: 'settings',
        nameKey: 'nav.shopSettings',
        route: '/shop',
        show: true,
        subMenus: [],
      },
    ],
  }),
  watch: {
    isOwner(newValue) {
      this.menus.find((m) => m.nameKey == 'nav.priceListEditor').show =
        newValue;
      //this.menus.find((m) => m.nameKey == 'nav.shopSettings').show = newValue;
      this.menus.find((m) => m.nameKey == 'nav.reports').show = newValue;
    },
  },
  mounted() {
    if (tailorState.shop != null) {
      shopsRepository
        .getShopSettings(tailorState.shop.id)
        .then((response) => {
          const settings = response.data;
          const bookingsSetting = settings.find((s) => s.key == 'BOOKINGS_ON');
          if (
            bookingsSetting &&
            parser.canParseBoolean(bookingsSetting.value)
          ) {
            const parsedValue = parser.parseBoolean(bookingsSetting.value);
            this.menus.find((m) => m.nameKey == 'nav.bookings').show =
              parsedValue;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    eventBus.$on('signIn', () => {
      this.isOwner = tailorState.roles.includes('Owner');
    });
    eventBus.$on('signOut', () => {
      this.isOwner = tailorState.roles.includes('Owner');
    });
    eventBus.$on('userIdRefreshed', () => {});
    eventBus.$on('userRolesRefreshed', () => {
      this.isOwner = tailorState.roles.includes('Owner');
    });

    this.isOwner = tailorState.roles.includes('Owner');
  },
};
</script>
