import i18n from '@/i18n';

export const customerExtensions = {
  isBlank(customer) {
    return (
      (!customer.name || customer.name == '') &&
      (!customer.surname || customer.surname == '') &&
      (!customer.email || customer.email == '')
    );
  },
  getAvailableNotifications(customerEmail) {
    if (customerEmail == null || customerEmail.length == 0) {
      return [
        i18n.tc('addPerson.notifs.sms'),
        i18n.tc('addPerson.notifs.none'),
      ];
    } else {
      return [
        i18n.tc('addPerson.notifs.sms'),
        i18n.tc('addPerson.notifs.email'),
        i18n.tc('addPerson.notifs.none'),
      ];
    }
  },
  getNotificationRules() {
    return [
      (v) => !!v || i18n.tc('addPerson.validation.notifMethod'),
      (v) => v.length > 0 || i18n.tc('addPerson.validation.notifMethod'),
    ];
  },
  getEmailRequiredRules() {
    return [
      (v) => (!!v && !!v.trim()) || i18n.tc('addPerson.validation.email1'),
      (v) =>
        /^[^@]+@[^@]+\.[^@]+$/.test(v) ||
        i18n.tc('addPerson.validation.email2'),
    ];
  },
  getEmailNotRequiredRules() {
    return [
      (v) =>
        v === '' ||
        /^[^@]+@[^@]+\.[^@]+$/.test(v) ||
        i18n.tc('addPerson.validation.email2'),
    ];
  },
  getPhoneNumberRules() {
    return [
      (v) => !!v || i18n.tc('addPerson.validation.phone1'),
      (v) =>
        /^1 \(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/.test(v) ||
        i18n.tc('addPerson.validation.phone2', {
          format: '1 (123)-123-1234',
        }),
    ];
  },
  getConfirmationRules() {
    return [(v) => !!v || i18n.tc('addPerson.validation.confirmation')];
  },
  getFirstNameRules() {
    return [(v) => !!v || i18n.tc('addPerson.validation.firstName')];
  },
  getLastNameRules() {
    return [(v) => !!v || i18n.tc('addPerson.validation.lastName')];
  },
  getPasswordRules() {
    return [(v) => !!v || 'Password is required'];
  },
};
