import supportedLocales from '@/configuration/supported-locales';

export default {
  getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (!navigatorLocale) {
      return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim();

    return trimmedLocale;
  },

  supportedLocalesInclude(locale) {
    return Object.keys(supportedLocales).includes(locale);
  },

  getStartingLocale() {
    const browserLocale = this.getBrowserLocale({ countryCodeOnly: true });

    if (this.supportedLocalesInclude(browserLocale)) {
      return browserLocale;
    } else {
      return process.env.VUE_APP_I18N_LOCALE || 'en';
    }
  },
};
