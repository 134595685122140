<template>
  <v-row class="fill-height" align="center" justify="center">
    <v-col cols="12" sm="8" md="6">
      <v-img :src="require('@/assets/logo_b.png')" contain />
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
