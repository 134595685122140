export const dateExtensions = {
  getDateFromISO: (isoString) => {
    return new Date(isoString.replace(/-/g, '/').replace('T', ' '));
  },
  getDateStringFromISO: (isoString) => {
    return new Date(
      isoString.replace(/-/g, '/').replace('T', ' ')
    ).toISOString();
  },
  getUtcNow: () => {
    return new Date(
      new Date().toISOString().replace(/-/g, '/').replace('T', ' ')
    );
  },
  getUtcDateNow: () => {
    return new Date(new Date().toDateString());
  },
  getUtcNowString: () => {
    let myDate = new Date();
    return new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate());
  },
  getUsFormat: (date) => {
    if (!date) return null;

    let [year, month, day] = date.split('-');
    if (day.length > 2) {
      day = day.substr(0, 2);
    }
    return `${month}/${day}/${year}`;
  },
  getTimeFormat(dateTime) {
    let date = new Date(dateTime);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + newformat;
  },

  getUsFormatWithoutLeadingZeros: (date) => {
    if (!date) return null;

    let [year, month, day] = date.split('-');
    if (day.length > 2) {
      day = day.substr(0, 2);
    }
    return `${month[0] == '0' ? month.substr(1, 1) : month}/${
      day[0] == '0' ? day.substr(1, 1) : day
    }/${year}`;
  },
  getNowISODate() {
    return new Date().toISOString().substr(0, 10);
  },
  getISODate(date) {
    return date.toISOString().substr(0, 10);
  },
  getISODateTime(date) {
    return date.toISOString().slice(0, -1);
  },
  getNowISOLocalDate() {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(Date.now() - tzoffset)
      .toISOString()
      .slice(0, -1)
      .substr(0, 10);
  },
  getNowISOLocalDateTime() {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  },
  getISOLocalDate(date) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - tzoffset).toISOString().slice(0, -1).substr(0, 10);
  },
  getISOLocalDateTime(date) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - tzoffset).toISOString().slice(0, -1);
  },
  getLocalDateTime(date) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - tzoffset).toLocaleString();
  },
  getLocalDate(date) {
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - tzoffset).toLocaleDateString();
  },
  parse(string) {
    const dateWithTimezone = new Date(string);
    const isoDateString = dateWithTimezone.toISOString();
    const isoStringWithoutTimezone = isoDateString.substring(
      0,
      isoDateString.length - 1
    );
    return new Date(isoStringWithoutTimezone);
  },
};
