import Vue from 'vue';
import VueRouter from 'vue-router';
import tailorState from '@/state/tailor-state';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/refactored/Dashboard.vue'),
  },
  {
    path: '/customers/search',
    name: 'customers-search',
    component: () => import('../modules/customer/views/CustomerSearch.vue'),
  },
  {
    path: '/customers/add',
    name: 'customers-add',
    component: () => import('../modules/customer/views/AddCustomer.vue'),
  },
  {
    path: '/customers/details',
    name: 'customers-details',
    component: () => import('../modules/customer/views/CustomerDetails.vue'),
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('../views/Bookings.vue'),
  },
  {
    path: '/orders/search',
    name: 'orders-search',
    component: () => import('../modules/order/views/OrdersSearch.vue'),
  },
  {
    path: '/orders/resume',
    name: 'orders-resume',
    component: () => import('../modules/order/views/OrdersResume.vue'),
  },
  {
    path: '/orders/pending',
    name: 'orders-pending',
    component: () => import('../modules/order/views/OrdersPending.vue'),
  },
  {
    path: '/orders/details',
    name: 'orders-details',
    component: () => import('../modules/order/views/OrderDetails.vue'),
  },
  {
    path: '/price-list',
    name: 'price-list',
    component: () => import('../modules/priceList/views/PriceList.vue'),
  },
  {
    path: '/price-list/editor',
    name: 'price-list-editor',
    component: () =>
      import('../modules/priceListEditor/views/PriceListEditor.vue'),
  },
  {
    path: '/shop',
    name: 'shop-settings',
    component: () => import('../views/refactored/settings/ShopSettings.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../modules/auth/views/Logout.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../modules/auth/views/Login.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../modules/auth/views/NotAuthorized.vue'),
  },
  {
    path: '/create-password',
    name: 'create-password',
    component: () => import('../modules/auth/views/CreatePassword.vue'),
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../modules/auth/views/PasswordReset.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../modules/report/views/Reports.vue'),
  },
  {
    path: '/garments/details',
    name: 'garments-details',
    component: () => import('../views/refactored/garments/GarmentDetails.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('../modules/auth/views/PasswordForgot.vue'),
  },
  {
    // catch all 404 - define at the very end
    path: '*',
    name: '404',
    component: () => import('../modules/auth/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name == 'create-password' && !tailorState.passwordResetNeeded) {
    next({ name: 'home' });
  }

  if (
    from.name == 'create-password' &&
    to.name != 'logout' &&
    tailorState.passwordResetNeeded
  ) {
    next({ name: 'create-password' });
  } else {
    next();
  }
});

export default router;
